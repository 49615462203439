import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  IconButton,
  Tooltip,
  Alert,
  SelectChangeEvent,
  Checkbox,
  Container
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import HistoryIcon from '@mui/icons-material/History';
import SecurityIcon from '@mui/icons-material/Security';
import InfoIcon from '@mui/icons-material/Info';
import PieChartIcon from '@mui/icons-material/PieChart';
import TimerIcon from '@mui/icons-material/Timer';
import BugReportIcon from '@mui/icons-material/BugReport';
import api from '../api/axios';

interface ConfigurationItem {
  [key: string]: string | number | boolean | string[] | Record<string, any>;
}

interface AuthenticationConfig {
  enabled: boolean;
  type: string[];
  username: string;
  password: string;
  token: string;
}

interface Configuration {
  project_name: string;
  target: string[];
  target_ip_address: string;
  target_port: string;
  charge_point_id: string;
  rate_limit: number;
  timeout: number;
  ssl: boolean;
  authentication: AuthenticationConfig;
}

interface ScanOptions {
  risk_level: string[];
}

interface TestConfigurationResponse {
  configuration: Configuration;
  scan_options: ScanOptions;
}

interface Finding {
  id: string;
  severity: 'critical' | 'high' | 'medium' | 'low' | 'informative';
  title: string;
  description: string;
  recommendation: string;
  evidence: string;
  certainty: 'high' | 'medium' | 'low';
  timestamp: string;
}

interface ScanResult {
  status: 'running' | 'completed' | 'failed';
  findings: Finding[];
  startTime: string;
  endTime?: string;
  duration?: number;
  severityStats: {
    critical: number;
    high: number;
    medium: number;
    low: number;
    informative: number;
  };
}

const SecurityOcppBlackbox = () => {
  const [configuration, setConfiguration] = useState<Configuration>({
    project_name: '',
    target: [],
    target_ip_address: '',
    target_port: '',
    charge_point_id: '',
    rate_limit: 0,
    timeout: 0,
    ssl: false,
    authentication: {
      enabled: false,
      type: [],
      username: '',
      password: '',
      token: ''
    }
  });
  const [scanOptions, setScanOptions] = useState<ScanOptions>({
    risk_level: []
  });
  const [configurationLoading, setConfigurationLoading] = useState(true);
  const [isScanRunning, setIsScanRunning] = useState(false);
  const [showPreviousResults, setShowPreviousResults] = useState(false);
  const [scanResult, setScanResult] = useState<ScanResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const response = await api.get('/api/security-scanner/configuration/');
        const data = response.data;

        // Convert arrays to single values for dropdowns
        const processConfig = (obj: any): any => {
          const newObj = { ...obj };
          Object.entries(newObj).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              newObj[key] = value[0];
              newObj[`${key}_options`] = value;
            } else if (value && typeof value === 'object') {
              newObj[key] = processConfig(value);
            } else if (key === 'target_ip_address') {
              // Store target_ip_address as placeholder and set empty value
              newObj[`${key}_placeholder`] = value;
              newObj[key] = '';
            }
          });
          return newObj;
        };

        setConfiguration(processConfig(data.configuration));
        setScanOptions(processConfig(data.scan_options));
        setConfigurationLoading(false);
      } catch (error) {
        console.error('Error fetching configuration:', error);
        setConfigurationLoading(false);
      }
    };

    fetchConfiguration();
  }, []);

  const renderConfigurationInputs = <T extends Record<string, any>>(
    configData: T,
    setConfigData: React.Dispatch<React.SetStateAction<T>>
  ) => {
    return Object.entries(configData).map(([key, value]) => {
      // Skip fields ending with _options
      if (key.endsWith('_options') || key.endsWith('_placeholder')) {
        return null;
      }

      // Handle dropdown fields (where we have _options)
      if (configData[`${key}_options`]) {
        return (
          <Grid item xs={12} key={key}>
            <FormControl fullWidth size="small">
              <InputLabel>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</InputLabel>
              <Select
                value={value || configData[`${key}_options`][0]}
                onChange={(e) => {
                  setConfigData({
                    ...configData,
                    [key]: e.target.value
                  });
                }}
                label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              >
                {configData[`${key}_options`].map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option.toString().toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      }

      // Handle nested objects (like authentication)
      if (value && typeof value === 'object') {
        return (
          <Grid item xs={12} key={key}>
            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
              {key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </Typography>
            <Box sx={{ pl: 2 }}>
              <Grid container spacing={2}>
                {Object.entries(value).map(([subKey, subValue]) => {
                  if (value[`${subKey}_options`]) {
                    return (
                      <Grid item xs={12} key={subKey}>
                        <FormControl fullWidth size="small">
                          <InputLabel>{subKey.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</InputLabel>
                          <Select
                            value={subValue || value[`${subKey}_options`][0]}
                            onChange={(e) => {
                              setConfigData({
                                ...configData,
                                [key]: {
                                  ...value,
                                  [subKey]: e.target.value
                                }
                              });
                            }}
                            label={subKey.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          >
                            {value[`${subKey}_options`].map((option: string) => (
                              <MenuItem key={option} value={option}>
                                {option.toString().toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  }

                  if (typeof subValue === 'boolean') {
                    return (
                      <Grid item xs={12} key={subKey}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!subValue}
                              onChange={(e) => {
                                setConfigData({
                                  ...configData,
                                  [key]: {
                                    ...value,
                                    [subKey]: e.target.checked
                                  }
                                });
                              }}
                            />
                          }
                          label={subKey.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        />
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={12} md={6} key={subKey}>
                      <TextField
                        fullWidth
                        size="small"
                        label={subKey.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        value={subValue ?? ''}
                        type={subKey.includes('password') || subKey.includes('token') ? 'password' : 'text'}
                        onChange={(e) => {
                          setConfigData({
                            ...configData,
                            [key]: {
                              ...value,
                              [subKey]: e.target.value
                            }
                          });
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        );
      }

      // Handle boolean values (checkbox)
      if (typeof value === 'boolean') {
        return (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value}
                  onChange={(e) => setConfigData({
                    ...configData,
                    [key]: e.target.checked
                  })}
                />
              }
              label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            />
          </Grid>
        );
      }

      // Handle text/number inputs
      return (
        <Grid item xs={12} md={6} key={key}>
          <TextField
            fullWidth
            size="small"
            label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            value={value ?? ''}
            type={typeof value === 'number' ? 'number' : 'text'}
            placeholder={key === 'target_ip_address' ? configData['target_ip_address_placeholder'] : undefined}
            onChange={(e) => setConfigData({
              ...configData,
              [key]: typeof value === 'number' ? Number(e.target.value) : e.target.value
            })}
            variant="outlined"
            InputProps={{
              style: { backgroundColor: 'white' }
            }}
          />
        </Grid>
      );
    }).filter(Boolean);
  };

  const isFormValid = () => {
    return (
      configuration.project_name &&
      configuration.target_ip_address &&
      configuration.target_port &&
      configuration.charge_point_id
    );
  };

  const startScan = async () => {
    try {
      setIsScanRunning(true);
      setError(null);
      setScanResult(null);

      // Merge scan options into configuration
      const mergedConfiguration = {
        ...configuration,
        ...scanOptions
      };

      const response = await api.post('/api/security-scanner/start/', {
        configuration: mergedConfiguration
      });
      setTaskId(response.data.task_id);
    } catch (error) {
      setError('Failed to start scan');
      setIsScanRunning(false);
    }
  };

  useEffect(() => {
    if (taskId) {
      const interval = setInterval(async () => {
        try {
          const response = await api.get(`/api/security-scanner/${taskId}/status/`);
          setScanResult(response.data);

          if (response.data.status === 'completed' || response.data.status === 'failed') {
            clearInterval(interval);
            setPollingInterval(null);
            setIsScanRunning(false);
          }
        } catch (error) {
          console.error('Failed to fetch scan status:', error);
          clearInterval(interval);
          setPollingInterval(null);
          setIsScanRunning(false);
        }
      }, 2000);

      setPollingInterval(interval);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [taskId]);

  const stopScan = async () => {
    try {
      //await api.post(`/api/security-scanner/${taskId}/stop/`);
      setIsScanRunning(false);
    } catch (error) {
      console.error('Failed to stop scan:', error);
    }
  };

  const getSeverityColor = (severity: string) => {
    const colors: { [key: string]: string } = {
      Critical: '#b71c1c', 
      High: '#f44336',
      Medium: '#ff9800',
      Low: '#4caf50',
      Informative: '#2196f3',
    };
    return colors[severity] || '#757575';
  };

  const calculateDuration = (startTime: string, endTime?: string) => {
    if (!endTime) return '-';
    const start = new Date(startTime);
    const end = new Date(endTime);
    const duration = (end.getTime() - start.getTime()) / 1000;
    return `${duration}s`;
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Configuration</Typography>
            <Grid container spacing={2}>
              {!configurationLoading && renderConfigurationInputs(configuration, setConfiguration)}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Scan Options</Typography>
            <Grid container spacing={2}>
              {!configurationLoading && renderConfigurationInputs(scanOptions, setScanOptions)}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={startScan}
            disabled={!isFormValid() || isScanRunning}
            sx={{ mt: 2 }}
          >
            {isScanRunning ? 'Scan Running...' : 'Start Scan'}
          </Button>
        </Grid>
        {scanResult && scanResult.findings && scanResult.findings.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mt: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Scan Results</Typography>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={3}>
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      <PieChartIcon sx={{ mr: 1 }} />
                      Status
                    </Typography>
                    <Chip
                      label={scanResult.status ? scanResult.status.toUpperCase() : 'UNKNOWN'}
                      color={
                        scanResult.status === 'completed'
                          ? 'success'
                          : scanResult.status === 'running'
                          ? 'warning'
                          : 'error'
                      }
                      sx={{ mt: 1 }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      <BugReportIcon sx={{ mr: 1 }} />
                      Findings
                    </Typography>
                    <Typography variant="h4" sx={{ mt: 1 }}>
                      {scanResult.findings ? scanResult.findings.length : 0}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      <TimerIcon sx={{ mr: 1 }} />
                      Duration
                    </Typography>
                    <Typography variant="h4" sx={{ mt: 1 }}>
                      {calculateDuration(scanResult.startTime, scanResult.endTime)}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mb: 2 }}>Findings</Typography>
              {scanResult.findings && scanResult.findings.map((finding) => (
                <Accordion key={finding.id} sx={{ mb: 1 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
                      <Chip
                        label={finding.severity.toUpperCase()}
                        size="small"
                        sx={{
                          bgcolor: getSeverityColor(finding.severity),
                          color: 'white',
                          width: 100,
                        }}
                      />
                      <Typography>{finding.title}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Description
                    </Typography>
                    <Typography paragraph>{finding.description}</Typography>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Recommendation
                    </Typography>
                    <Typography>{finding.recommendation}</Typography>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Evidence
                    </Typography>
                    <Typography paragraph>{finding.evidence}</Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                      <Typography variant="caption" color="text.secondary">
                        Found at: {new Date(finding.timestamp).toLocaleString()}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Paper>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SecurityOcppBlackbox;
