import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Chip,
  Alert,
  LinearProgress,
  CircularProgress,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import api from '../api/axios';
import { styled } from '@mui/material/styles';

interface StartTestResponse {
  task_id: string;
  message: string;
}

interface TestStatusResponse {
  status: string;
  task_id: string;
  total_count: number;
  completed_count: number;
  passed_count: number;
  result: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  traffic: {
    [key: string]: {
      [key: string]: {
        timestamp: string;
        direction: string;
        message: any;
      }[];
    };
  };
  error_message?: string;
}

interface TestCase {
  [key: string]: string[];
}

interface AvailableTests {
  csms_to_cp: TestCase;
  cp_to_csms: TestCase;
}

interface SelectedTests {
  [key: string]: string[];
}

type Direction = 'cp_to_csms' | 'csms_to_cp';

interface AuthenticationConfig {
  enabled: boolean;
  type: string[];
  username: string;
  password: string;
  token: string;
}

interface Configuration {
  project_name: string;
  direction: string[];
  target_url: string;
  charge_point_id: string;
  charge_point_vendor: string;
  charge_point_model: string;
  charge_point_serial_number: string;
  charge_box_serial_number: string;
  firmware_version: string;
  iccid: string;
  imsi: string;
  meter_serial_number: string;
  meter_type: string;
  authentication: AuthenticationConfig;
}

interface TestOptions {
  test_level: string[];
  test_profile: string[];
}

interface ConfigurationItem {
  [key: string]: string | number | boolean | string[] | {
    [key: string]: string | number | boolean | string[];
  };
}

interface TestConfigurationResponse {
  configuration: ConfigurationItem;
  test_data: ConfigurationItem;
}

interface TestConfiguration {
  configuration: ConfigurationItem;
  test_data: ConfigurationItem;
}

const ConfigurationPaper = styled(Paper)(({ theme }) => ({
  '& .MuiTextField-root, & .MuiFormControl-root': {
    marginBottom: theme.spacing(2),
  },
  '& .header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  }
}));

const SuccessAlert = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 9999,
}));

const OcppComplianceTesting: React.FC = () => {
  const [projectName, setProjectName] = useState('');
  const [direction, setDirection] = useState<Direction>('cp_to_csms');
  const [availableTests, setAvailableTests] = useState<AvailableTests | null>(null);
  const [selectedTests, setSelectedTests] = useState<SelectedTests>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [testStatus, setTestStatus] = useState<TestStatusResponse | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
  const [configuration, setConfiguration] = useState<ConfigurationItem>({});
  const [testData, setTestData] = useState<ConfigurationItem>({});
  const [configurationLoading, setConfigurationLoading] = useState(true);
  const [configSaved, setConfigSaved] = useState(false);

  // Form validation
  const isFormValid = () => {
    return (
      projectName.trim() !== '' && 
      Object.keys(selectedTests).length > 0 &&
      Object.values(selectedTests).some(methods => methods.length > 0)
    );
  };

  // Load available test cases on component mount
  useEffect(() => {
    const fetchAvailableTests = async () => {
      try {
        const response = await api.get('/api/ocpp-compliance-test/available-tests/');
        setAvailableTests(response.data);
      } catch (error) {
        console.error('Failed to fetch available tests:', error);
      }
    };

    fetchAvailableTests();
  }, [direction]);

  // Fetch default configuration on component mount
  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        setConfigurationLoading(true);
        const response = await api.get<TestConfigurationResponse>('/api/ocpp-compliance-test/configuration/');
        setConfiguration(response.data.configuration);
        setTestData(response.data.test_data);
      } catch (error) {
        console.error('Failed to fetch configuration:', error);
        setError('Failed to load default configuration');
      } finally {
        setConfigurationLoading(false);
      }
    };

    fetchConfiguration();
  }, []);

  // Cleanup polling on unmount
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  // Poll for test status
  useEffect(() => {
    if (!taskId) return;

    const pollStatus = async () => {
      try {
        const response = await api.get(`/api/ocpp-compliance-test/${taskId}/status/`);
        setTestStatus(response.data);

        // Stop polling if test is complete
        if (response.data.status === 'completed' || response.data.status === 'failed') {
          if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
          }
        }
      } catch (error) {
        console.error('Failed to fetch test status:', error);
        if (pollingInterval) {
          clearInterval(pollingInterval);
          setPollingInterval(null);
        }
      }
    };

    // Start polling
    const interval = setInterval(pollStatus, 2000);
    setPollingInterval(interval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [taskId]);

  // Simplified test selection handlers
  const handleTestSelection = (testClass: string, method: string) => {
    setSelectedTests(prev => {
      const newTests = { ...prev };
      if (!newTests[testClass]) {
        newTests[testClass] = [];
      }
      const index = newTests[testClass].indexOf(method);
      if (index === -1) {
        newTests[testClass] = [...newTests[testClass], method];
      } else {
        newTests[testClass] = newTests[testClass].filter(m => m !== method);
      }
      // Remove empty test classes
      if (newTests[testClass].length === 0) {
        delete newTests[testClass];
      }
      return newTests;
    });
  };

  const handleSelectAll = () => {
    if (!availableTests) return;
    
    const allSelected = Object.entries(availableTests[direction]).every(
      ([testClass, methods]) => methods.every(method => 
        selectedTests[testClass]?.includes(method)
      )
    );

    if (allSelected) {
      setSelectedTests({});
    } else {
      const newTests: SelectedTests = {};
      Object.entries(availableTests[direction]).forEach(([testClass, methods]) => {
        newTests[testClass] = [...methods];
      });
      setSelectedTests(newTests);
    }
  };

  const isTestSelected = (testClass: string, method: string) => {
    return selectedTests[testClass]?.includes(method) || false;
  };

  const getSelectedTestCount = () => {
    return Object.values(selectedTests).reduce((sum, methods) => sum + methods.length, 0);
  };

  const handleStartTest = async () => {
    if (!isFormValid()) {
      setError('Please fill in all required fields and select at least one test case');
      return;
    }
    
    setLoading(true);
    setError(null);
    setTaskId(null);
    setTestStatus(null);

    try {
      const requestData = {
        configuration: {
          project_name: projectName,
          test_configuration: {
            ...configuration,
            direction: direction
          },
          test_cases: selectedTests,
          test_data: testData
        }
      };

      const response = await api.post<StartTestResponse>(
        '/api/ocpp-compliance-test/start/',
        requestData
      );
      setTaskId(response.data.task_id);
    } catch (err: any) {
      setError(err.response?.data?.message || 'An error occurred while starting the test');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveConfiguration = () => {
    // Here you could add API call to save configuration if needed
    setConfigSaved(true);
    setTimeout(() => setConfigSaved(false), 3000);
  };

  const handleSaveTestData = () => {
    // Here you could add API call to save test data if needed
    setConfigSaved(true);
    setTimeout(() => setConfigSaved(false), 3000);
  };

  const renderConfigurationInputs = (configData: ConfigurationItem, setConfigData: React.Dispatch<React.SetStateAction<ConfigurationItem>>) => {
    return Object.entries(configData).map(([key, value]) => {
      // Handle array values (dropdown)
      if (Array.isArray(value)) {
        const currentValue = configData[key];
        const selectedValue = Array.isArray(currentValue) ? currentValue[0] : currentValue || value[0];
        
        return (
          <Grid item xs={12} key={key}>
            <FormControl fullWidth size="small">
              <InputLabel>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</InputLabel>
              <Select
                value={selectedValue}
                onChange={(e) => setConfigData({
                  ...configData,
                  [key]: e.target.value
                })}
                label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              >
                {value.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option.toString().toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      }

      // Handle boolean values (checkbox)
      if (typeof value === 'boolean') {
        return (
          <Grid item xs={12} key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value}
                  onChange={(e) => setConfigData({
                    ...configData,
                    [key]: e.target.checked
                  })}
                />
              }
              label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            />
          </Grid>
        );
      }

      // Handle text/number inputs
      const isSensitive = key.includes('password') || key.includes('key');
      const inputType = typeof value === 'number' ? 'number' : (isSensitive ? 'password' : 'text');

      return (
        <Grid item xs={12} key={key}>
          <TextField
            fullWidth
            size="small"
            label={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            value={value ?? ''}
            type={inputType}
            onChange={(e) => setConfigData({
              ...configData,
              [key]: inputType === 'number' ? Number(e.target.value) : e.target.value
            })}
            variant="outlined"
            InputProps={{
              style: { backgroundColor: 'white' }
            }}
          />
        </Grid>
      );
    });
  };

  return (
    <Box className="p-6">
      {configSaved && (
        <SuccessAlert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setConfigSaved(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Configuration applied!
        </SuccessAlert>
      )}

      <Box sx={{ mb: 4, display: 'flex', gap: 2, alignItems: 'center' }}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Target</InputLabel>
          <Select
            value={direction}
            onChange={(e) => setDirection(e.target.value as Direction)}
            label="Target"
          >
            <MenuItem value="cp_to_csms">CSMS</MenuItem>
            <MenuItem value="csms_to_cp">CP</MenuItem>
          </Select>
        </FormControl>

        <TextField
          size="small"
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          sx={{ minWidth: 200 }}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={loading ? <CircularProgress size={20} /> : <PlayArrowIcon />}
          onClick={handleStartTest}
          disabled={!isFormValid() || loading}
        >
          {loading ? 'Running Tests...' : 'Start Tests'}
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ConfigurationPaper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Configuration</Typography>
            <Grid container spacing={2}>
              {!configurationLoading && renderConfigurationInputs(configuration, setConfiguration)}
            </Grid>
          </ConfigurationPaper>
        </Grid>

        <Grid item xs={12} md={6}>
          <ConfigurationPaper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Test Data</Typography>
            <Grid container spacing={2}>
              {!configurationLoading && renderConfigurationInputs(testData, setTestData)}
            </Grid>
          </ConfigurationPaper>
        </Grid>
      </Grid>

      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6">Available Test Cases</Typography>
            <Chip 
              label={`${getSelectedTestCount()} selected`}
              color="primary"
              variant="outlined"
              size="small"
            />
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSelectAll}
            sx={{ mr: 1 }}
            disabled={loading}
          >
            {Object.keys(selectedTests).length === 0 ? 'Select All' : 'Deselect All'}
          </Button>
        </Box>

        {availableTests ? (
          <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
            {Object.entries(availableTests[direction]).map(([testClass, testMethods]) => (
              <Accordion key={testClass}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Checkbox
                      checked={selectedTests[testClass]?.length === testMethods.length}
                      indeterminate={selectedTests[testClass]?.length > 0 && selectedTests[testClass]?.length < testMethods.length}
                      onChange={(e) => {
                        e.stopPropagation();
                        const newSelectedTests = { ...selectedTests };
                        if (e.target.checked) {
                          newSelectedTests[testClass] = testMethods;
                        } else {
                          delete newSelectedTests[testClass];
                        }
                        setSelectedTests(newSelectedTests);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <Typography>{testClass}</Typography>
                    <Chip 
                      size="small" 
                      label={`${selectedTests[testClass]?.length || 0}/${testMethods.length} selected`}
                      color="primary"
                      variant="outlined"
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense>
                    {testMethods.map((method) => (
                      <ListItem
                        key={method}
                        onClick={() => handleTestSelection(testClass, method)}
                        sx={{ 
                          cursor: 'pointer',
                          '&:hover': { bgcolor: 'action.hover' },
                          bgcolor: isTestSelected(testClass, method) ? 'action.selected' : 'transparent'
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={isTestSelected(testClass, method)}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={method} />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>

      {testStatus && (
        <Paper elevation={2} sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Test Results</Typography>
          {testStatus.result && Object.entries(testStatus.result).map(([testClass, tests]) => (
            <Accordion key={testClass}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Object.values(tests).every(result => result)}
                      indeterminate={Object.values(tests).some(result => result) && !Object.values(tests).every(result => result)}
                      onChange={(e) => {
                        const newSelectedTests = { ...selectedTests };
                        if (e.target.checked) {
                          newSelectedTests[testClass] = Object.keys(tests);
                        } else {
                          delete newSelectedTests[testClass];
                        }
                        setSelectedTests(newSelectedTests);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label={testClass}
                  onClick={(e) => e.stopPropagation()}
                />
                <Chip 
                  size="small"
                  label={`${Object.values(tests).filter(Boolean).length}/${Object.keys(tests).length} passed`}
                  color={Object.values(tests).every(Boolean) ? "success" : "warning"}
                />
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {Object.entries(tests).map(([testName, passed]) => (
                    <ListItem key={testName}>
                      <ListItemIcon>
                        {passed ? 
                          <CheckCircleIcon color="success" /> : 
                          <ErrorIcon color="error" />
                        }
                      </ListItemIcon>
                      <ListItemText 
                        primary={testName}
                        secondary={
                          testStatus.traffic?.[testClass]?.[testName]?.map((traffic: any, index: number) => (
                            <Box 
                              key={index} 
                              sx={{ 
                                mt: 1,
                                p: 1,
                                backgroundColor: 'grey.100',
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                fontSize: '0.875rem'
                              }}
                            >
                              <Typography variant="caption" display="block" color="textSecondary">
                                {traffic.timestamp} - {traffic.direction}
                              </Typography>
                              <pre style={{ margin: 0, overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                {JSON.stringify(traffic.message, null, 2)}
                              </pre>
                            </Box>
                          ))
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default OcppComplianceTesting;