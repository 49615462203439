import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  ButtonGroup,
  CircularProgress,
  Alert,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  MagnifyingGlassIcon,
  FunnelIcon,
  ArrowsPointingOutIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import api from '../api/axios';

interface BaseReportData {
  task_id?: string;
  direction: string;
  charge_point_id?: string;
  uri: string;
  status: string;
  completed_at: string;
  project_name: string;
  product: string;
}

interface SecurityFinding {
  severity: 'LOW' | 'MEDIUM' | 'HIGH' | 'CRITICAL';
  description: string;
  remediation: string;
  evidence: string;
  timestamp: string;
  traffic?: {
    timestamp: string;
    direction: string;
    message: any;
  }[];
}

interface SecurityReportData extends BaseReportData {
  findings?: SecurityFinding[];
  traffic?: Array<Array<{
    timestamp: string;
    direction: string;
    message: any;
  }>>;
}

interface ComplianceReportData extends BaseReportData {
  results?: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  traffic?: {
    [category: string]: {
      [test: string]: Array<{
        timestamp: string;
        direction: string;
        message: any;
      }>;
    };
  };
}

type ReportData = SecurityReportData | ComplianceReportData;

interface Report {
  id: number;
  user: number;
  report: string;
  project_name: string;
  product: string;
  created_at: string;
}

const Reports = () => {
  const [selectedReports, setSelectedReports] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get("/api/reports/");
      setReports(response.data);
    } catch (err) {
      setError('Failed to fetch reports. Please try again later.');
      console.error('Error fetching reports:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = async (report: Report) => {
    try {
      setDetailsLoading(true);
      const reportData = parseReport(report.report);
      const response = await api.get(`/api/reports/${reportData.task_id}/`);
      setSelectedReport(response.data);
      setDetailsDialogOpen(true);
    } catch (err) {
      setError('Failed to fetch report details. Please try again later.');
      console.error('Error fetching report details:', err);
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedReport(null);
  };

  const handleReportSelect = (reportId: number) => {
    setSelectedReports(prev => 
      prev.includes(reportId) 
        ? prev.filter(id => id !== reportId)
        : [...prev, reportId]
    );
  };

  const handleSearch = () => {
    // Filter reports based on searchTerm
  };

  const parseReport = (reportStr: string): ReportData => {
    try {
      return JSON.parse(reportStr);
    } catch (e) {
      console.error('Error parsing report:', e);
      return {} as ReportData;
    }
  };

  const getTestResults = (results?: ComplianceReportData['results']) => {
    if (!results) {
      return { passed: 0, total: 0, percentage: 0 };
    }
  
    let passed = 0;
    let total = 0;
  
    Object.values(results).forEach(category => {
      Object.values(category).forEach(result => {
        total++;
        if (result) passed++;
      });
    });
  
    return { passed, total, percentage: Math.round((passed / total) * 100) };
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'running':
        return 'bg-blue-100 text-blue-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const renderTestDetails = (report: Report) => {
    const reportData = parseReport(report.report);
    return (
      <Box className="space-y-4">
        <Box>
          <Typography variant="h6" className="mb-2">Test Information</Typography>
          <Typography><strong>Test ID:</strong> {reportData.task_id}</Typography>
          <Typography><strong>Project Name:</strong> {reportData.project_name}</Typography>
          <Typography><strong>URI:</strong> {reportData.uri}</Typography>
          <Typography><strong>Status:</strong> {reportData.status}</Typography>
          <Typography><strong>Completed At:</strong> {new Date(reportData.completed_at).toLocaleString()}</Typography>
        </Box>
        
        {('findings' in reportData) && reportData.findings && (
          <Box>
            <Typography variant="h6" className="mb-2">Security Findings</Typography>
            {reportData.findings.map((finding, index) => (
              <Box 
                key={index} 
                className="mb-4 p-4 rounded-lg"
                sx={{ 
                  backgroundColor: finding.severity.toUpperCase() === 'CRITICAL' ? 'rgba(254, 226, 226, 0.4)' :
                                 finding.severity.toUpperCase() === 'HIGH' ? 'rgba(254, 243, 199, 0.4)' :
                                 finding.severity.toUpperCase() === 'MEDIUM' ? 'rgba(224, 242, 254, 0.4)' : 'rgba(220, 252, 231, 0.4)',
                  border: '1px solid',
                  borderColor: finding.severity.toUpperCase() === 'CRITICAL' ? 'rgb(254, 226, 226)' :
                             finding.severity.toUpperCase() === 'HIGH' ? 'rgb(254, 243, 199)' :
                             finding.severity.toUpperCase() === 'MEDIUM' ? 'rgb(224, 242, 254)' : 'rgb(220, 252, 231)'
                }}
              >
                <Typography variant="subtitle1" className="font-medium" sx={{ 
                  color: finding.severity.toUpperCase() === 'CRITICAL' ? '#991B1B' :
                         finding.severity.toUpperCase() === 'HIGH' ? '#92400E' :
                         finding.severity.toUpperCase() === 'MEDIUM' ? '#075985' : '#166534',
                  fontSize: '1.1rem',
                  mb: 2
                }}>
                  {finding.severity} - {finding.description}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ mb: 1 }}><strong>Remediation:</strong> {finding.remediation}</Typography>
                  <Typography><strong>Evidence:</strong> {finding.evidence}</Typography>
                  {reportData.traffic && reportData.traffic[index]?.length > 0 && (
                    <Box sx={{ mt: 1, ml: 3 }}>
                      {reportData.traffic[index].map((traffic, trafficIndex) => (
                        <Box 
                          key={trafficIndex} 
                          sx={{ 
                            p: 2,
                            my: 1,
                            backgroundColor: 'white',
                            borderRadius: 1,
                            fontFamily: 'monospace',
                            fontSize: '0.875rem',
                            border: '1px solid',
                            borderColor: 'grey.300',
                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                          }}
                        >
                          <Typography variant="caption" display="block" color="textSecondary" sx={{ mb: 1 }}>
                            {new Date(traffic.timestamp).toLocaleString()} - {traffic.direction.toUpperCase()}
                          </Typography>
                          <pre style={{ 
                            margin: 0, 
                            overflow: 'auto', 
                            whiteSpace: 'pre-wrap', 
                            wordBreak: 'break-word',
                            backgroundColor: 'rgba(0, 0, 0, 0.02)',
                            padding: '8px',
                            borderRadius: '4px'
                          }}>
                            {JSON.stringify(traffic.message, null, 2)}
                          </pre>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Typography variant="caption" color="textSecondary">
                  {new Date(finding.timestamp).toLocaleString()}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        
        {('results' in reportData) && reportData.results && (
          <Box>
            <Typography variant="h6" className="mb-2">Test Results</Typography>
            {Object.entries(reportData.results).map(([category, tests]) => (
              <Box key={category} className="mb-4">
                <Typography variant="subtitle1" className="font-medium">{category}</Typography>
                <Box className="ml-4">
                  {Object.entries(tests).map(([test, result]) => (
                    <Box key={test}>
                      <Box className="flex items-center gap-2">
                        {result ? (
                          <CheckCircleIcon className="w-4 h-4 text-green-500" />
                        ) : (
                          <XCircleIcon className="w-4 h-4 text-red-500" />
                        )}
                        <Typography>{test}</Typography>
                      </Box>
                      {reportData.traffic?.[category]?.[test] && (
                        <Box sx={{ ml: 6, mt: 1, mb: 2 }}>
                          <Typography variant="subtitle2" sx={{ mb: 1 }}>Traffic:</Typography>
                          {reportData.traffic[category][test].map((traffic, index) => (
                            <Box 
                              key={index} 
                              sx={{ 
                                p: 2,
                                my: 1,
                                backgroundColor: 'white',
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                fontSize: '0.875rem',
                                border: '1px solid',
                                borderColor: 'grey.300',
                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
                              }}
                            >
                              <Typography variant="caption" display="block" color="textSecondary" sx={{ mb: 1 }}>
                                {new Date(traffic.timestamp).toLocaleString()} - {traffic.direction.toUpperCase()}
                              </Typography>
                              <pre style={{ 
                                margin: 0, 
                                overflow: 'auto', 
                                whiteSpace: 'pre-wrap', 
                                wordBreak: 'break-word',
                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                padding: '8px',
                                borderRadius: '4px'
                              }}>
                                {JSON.stringify(traffic.message, null, 2)}
                              </pre>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };
  
  if (loading) {
    return (
      <Box className="flex justify-center items-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="p-6">
      {error && (
        <Alert severity="error" className="mb-4">
          {error}
        </Alert>
      )}

      <Box className="flex justify-between items-center mb-6">
        <Typography variant="h5" className="font-medium">
          OCPP Test Reports
        </Typography>
        <Box className="flex gap-4">
          <TextField
            size="small"
            placeholder="Search reports..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-64"
          />
          <ButtonGroup>
            <Button
              variant="outlined"
              startIcon={<MagnifyingGlassIcon className="w-4 h-4" />}
              onClick={handleSearch}
            >
              search
            </Button>
            <Button
              variant="outlined"
              startIcon={<FunnelIcon className="w-4 h-4" />}
            >
              filter
            </Button>
            <Button
              variant="outlined"
              startIcon={<ArrowsPointingOutIcon className="w-4 h-4" />}
            >
              sort by
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Box className="space-y-4">
        {reports.length === 0 ? (
          <Typography className="text-center text-gray-500 py-8">
            No reports found
          </Typography>
        ) : (
          reports.map((report) => {
            const reportData = parseReport(report.report);
            const results = ('results' in reportData) && reportData.results ? getTestResults(reportData.results) : null;
            
            return (
              <Card 
                key={report.id}
                className={`border ${selectedReports.includes(report.id) ? 'border-primary-600' : 'border-gray-200'}`}
              >
                <CardContent className="flex items-center justify-between p-4">
                  <Box className="flex items-center gap-4">
                    <input
                      type="checkbox"
                      checked={selectedReports.includes(report.id)}
                      onChange={() => handleReportSelect(report.id)}
                      className="w-4 h-4 text-primary-600"
                    />
                    <Box>
                      <Typography className="font-medium">
                        {report.project_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="flex gap-2 items-center">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      report.product === 'Security Scanner' 
                        ? 'bg-red-100 text-red-800'
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {report.product === 'Security Scanner' ? 'security' : 'compliance test'}
                    </span>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(reportData.status)}`}>
                      {reportData.status}
                    </span>
                    {results && (
                      <Chip
                        icon={results.percentage >= 70 ? 
                          <CheckCircleIcon className="w-4 h-4 text-green-500" /> : 
                          <XCircleIcon className="w-4 h-4 text-red-500" />
                        }
                        label={`${results.passed}/${results.total} (${results.percentage}%)`}
                        color={results.percentage >= 70 ? "success" : "error"}
                        size="small"
                      />
                    )}
                  </Box>
                  <Box className="flex items-center gap-2">
                    <ButtonGroup size="small">
                      <Button onClick={() => handleViewDetails(report)}>View Details</Button>
                      <Button>Download</Button>
                    </ButtonGroup>
                  </Box>
                </CardContent>
              </Card>
            );
          })
        )}
      </Box>

      {selectedReports.length > 1 && (
        <Box className="fixed bottom-6 right-6">
          <Button
            variant="contained"
            color="primary"
            className="bg-primary-600"
          >
            Compare Selected ({selectedReports.length})
          </Button>
        </Box>
      )}

      <Dialog
        open={detailsDialogOpen}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Test Report Details
        </DialogTitle>
        <DialogContent>
          {detailsLoading ? (
            <Box className="flex justify-center items-center py-8">
              <CircularProgress />
            </Box>
          ) : selectedReport && (
            renderTestDetails(selectedReport)
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Reports;
