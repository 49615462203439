import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Chip,
  Tooltip,
  IconButton,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { format, subDays, parseISO } from 'date-fns';
import { saveAs } from 'file-saver';
import {
  ArrowTrendingUpIcon,
  ArrowTrendingDownIcon,
  ClockIcon,
  UsersIcon,
  BuildingOfficeIcon,
  DocumentCheckIcon,
  FunnelIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import api from '../api/axios';

interface Company {
  id: number;
  name: string;
  email: string;
  website: string;
  address: string;
  phone: string;
  avatar: string | null;
  description: string;
  user_limit: number;
  member_count: number;
  created_at: string;
}

interface Finding {
  title: string;
  description: string;
  severity: 'Critical' | 'High' | 'Medium' | 'Low' | 'Info';
  remediation?: string;
  evidence?: string;
}

interface ReportData {
  status: string;
  findings?: Finding[];
}

interface Report {
  id: number;
  user: number;
  report: string;
  project_name: string;
  product: string;
  created_at: string;
}

interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  date_joined: string;
  last_login: string;
}

interface FilterOptions {
  dateRange: '7days' | '30days' | '90days';
  severity: 'all' | 'critical' | 'high' | 'medium' | 'low';
  status: 'all' | 'completed' | 'pending';
}

const Dashboard: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [recentReports, setRecentReports] = useState<Report[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterOptions>({
    dateRange: '7days',
    severity: 'all',
    status: 'all',
  });
  const [userActivityData, setUserActivityData] = useState<any[]>([]);
  const [securityMetrics, setSecurityMetrics] = useState<any>({});

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [companiesRes, reportsRes, usersRes] = await Promise.all([
          api.get('/api/companies/'),
          api.get('/api/reports/recents/'),
          api.get('/api/admin/users/')
        ]);

        setCompanies(companiesRes.data.results);
        setRecentReports(reportsRes.data);
        setUsers(usersRes.data);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to fetch dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const getTestResults = (reports: Report[]) => {
    const totalTests = reports.length;
    let criticalCount = 0;
    let highCount = 0;
    let mediumCount = 0;
    let lowCount = 0;
    let successCount = 0;

    reports.forEach(report => {
      try {
        const reportData = JSON.parse(report.report) as ReportData;
        if (reportData.status === 'completed') {
          if (!reportData.findings || reportData.findings.length === 0) {
            successCount++;
            return;
          }

          reportData.findings.forEach(finding => {
            switch (finding.severity) {
              case 'Critical':
                criticalCount++;
                break;
              case 'High':
                highCount++;
                break;
              case 'Medium':
                mediumCount++;
                break;
              case 'Low':
                lowCount++;
                break;
            }
          });
        }
      } catch {
        // Skip invalid reports
      }
    });

    return {
      successful: Math.round((successCount / totalTests) * 100) || 0,
      failed: Math.round(((totalTests - successCount) / totalTests) * 100) || 0,
      findings: {
        critical: criticalCount,
        high: highCount,
        medium: mediumCount,
        low: lowCount,
      }
    };
  };

  const getCompanyStats = () => {
    const total = companies.length;
    const activeCompanies = companies.filter(c => c.member_count > 0).length;
    const totalMembers = companies.reduce((sum, c) => sum + c.member_count, 0);
    const avgMembersPerCompany = totalMembers / total || 0;

    return {
      total,
      active: activeCompanies,
      inactive: total - activeCompanies,
      totalMembers,
      avgMembers: Math.round(avgMembersPerCompany * 10) / 10,
    };
  };

  const getUserStats = () => {
    const total = users.length;
    const activeUsers = users.filter(u => u.is_active).length;
    const recentUsers = users.filter(u => {
      const lastLogin = new Date(u.last_login);
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return lastLogin > oneWeekAgo;
    }).length;

    return {
      total,
      active: activeUsers,
      inactive: total - activeUsers,
      recentlyActive: recentUsers,
    };
  };

  const generateUserActivityData = () => {
    const days = filters.dateRange === '7days' ? 7 : filters.dateRange === '30days' ? 30 : 90;
    const data = [];
    
    for (let i = days; i >= 0; i--) {
      const date = subDays(new Date(), i);
      const formattedDate = format(date, 'yyyy-MM-dd');
      const activeUsers = users.filter(user => {
        if (!user?.last_login) return false;
        try {
          const loginDate = format(parseISO(user.last_login), 'yyyy-MM-dd');
          return loginDate === formattedDate;
        } catch {
          return false;
        }
      }).length;

      data.push({
        x: formattedDate,
        y: activeUsers,
      });
    }

    setUserActivityData([{
      id: 'Active Users',
      data: data,
    }]);
  };

  const generateSecurityMetrics = () => {
    const metrics = {
      severityDistribution: [] as any[],
      testResults: [] as any[],
      trendData: [] as any[],
    };

    // Severity distribution
    const severityCounts = {
      Critical: 0,
      High: 0,
      Medium: 0,
      Low: 0,
    };

    recentReports.forEach(report => {
      if (!report?.report) return;
      try {
        const reportData = JSON.parse(report.report) as ReportData;
        if (!reportData?.findings) return;
        reportData.findings.forEach(finding => {
          if (finding?.severity && severityCounts.hasOwnProperty(finding.severity)) {
            severityCounts[finding.severity as keyof typeof severityCounts]++;
          }
        });
      } catch {
        // Skip invalid reports
      }
    });

    metrics.severityDistribution = Object.entries(severityCounts).map(([id, value]) => ({
      id,
      label: id,
      value,
      color: id === 'Critical' ? '#ef4444' : 
             id === 'High' ? '#f97316' : 
             id === 'Medium' ? '#3b82f6' : 
             '#22c55e',
    }));

    // Test results over time
    const days = filters.dateRange === '7days' ? 7 : filters.dateRange === '30days' ? 30 : 90;
    const trendData = [];
    
    for (let i = days; i >= 0; i--) {
      const date = subDays(new Date(), i);
      const formattedDate = format(date, 'yyyy-MM-dd');
      const dayReports = recentReports.filter(report => {
        if (!report?.created_at) return false;
        try {
          return format(parseISO(report.created_at), 'yyyy-MM-dd') === formattedDate;
        } catch {
          return false;
        }
      });

      const passed = dayReports.filter(report => {
        if (!report?.report) return false;
        try {
          const reportData = JSON.parse(report.report) as ReportData;
          return reportData?.status === 'completed' && (!reportData?.findings || reportData.findings.length === 0);
        } catch {
          return false;
        }
      }).length;

      trendData.push({
        date: formattedDate,
        Passed: passed,
        Failed: dayReports.length - passed,
      });
    }

    metrics.trendData = trendData;
    setSecurityMetrics(metrics);
  };

  const handleExport = (type: 'pdf' | 'csv') => {
    const data = recentReports.map(report => {
      if (!report?.report) return null;
      try {
        const reportData = JSON.parse(report.report) as ReportData;
        return {
          project: report.project_name || 'N/A',
          product: report.product || 'N/A',
          status: reportData?.status || 'N/A',
          findings: reportData?.findings?.length || 0,
          critical: reportData?.findings?.filter(f => f?.severity === 'Critical').length || 0,
          high: reportData?.findings?.filter(f => f?.severity === 'High').length || 0,
          created: report.created_at ? new Date(report.created_at).toLocaleString() : 'N/A',
        };
      } catch {
        return null;
      }
    }).filter(Boolean);

    if (type === 'csv') {
      const csv = [
        ['Project', 'Product', 'Status', 'Findings', 'Critical', 'High', 'Created'],
        ...data.map(d => [
          d?.project || '',
          d?.product || '',
          d?.status || '',
          d?.findings || 0,
          d?.critical || 0,
          d?.high || 0,
          d?.created || '',
        ]),
      ].map(row => row.join(',')).join('\n');

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `security-report-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    }
  };

  useEffect(() => {
    generateUserActivityData();
    generateSecurityMetrics();
  }, [users, recentReports, filters]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const testData = getTestResults(recentReports);
  const companyStats = getCompanyStats();
  const userStats = getUserStats();

  return (
    <Box sx={{ display: 'flex', bgcolor: '#F8FAFC', minHeight: '100vh' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Filters Section */}
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Date Range</InputLabel>
                <Select
                  value={filters.dateRange}
                  label="Date Range"
                  onChange={(e) => setFilters(prev => ({ ...prev, dateRange: e.target.value as FilterOptions['dateRange'] }))}
                >
                  <MenuItem value="7days">Last 7 days</MenuItem>
                  <MenuItem value="30days">Last 30 days</MenuItem>
                  <MenuItem value="90days">Last 90 days</MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Severity</InputLabel>
                <Select
                  value={filters.severity}
                  label="Severity"
                  onChange={(e) => setFilters(prev => ({ ...prev, severity: e.target.value as FilterOptions['severity'] }))}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="critical">Critical</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status}
                  label="Status"
                  onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value as FilterOptions['status'] }))}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                variant="outlined"
                startIcon={<ArrowDownTrayIcon className="w-5 h-5" />}
                onClick={() => handleExport('csv')}
              >
                Export CSV
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Summary Cards */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 8px -1px rgba(0,0,0,0.1)',
              }
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <UsersIcon className="w-6 h-6 text-indigo-500 mr-2" />
                  <Typography variant="subtitle1" sx={{ color: '#64748B', fontWeight: 600 }}>
                    User Activity
                  </Typography>
                </Box>
                <Typography variant="h3" sx={{ mb: 1, color: '#0F172A', fontWeight: 700 }}>
                  {userStats.active}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ color: '#64748B' }}>
                    Active Users
                  </Typography>
                  <Chip 
                    label={`+${userStats.recentlyActive} this week`}
                    size="small"
                    color="success"
                    sx={{ height: 20 }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 8px -1px rgba(0,0,0,0.1)',
              }
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <BuildingOfficeIcon className="w-6 h-6 text-indigo-500 mr-2" />
                  <Typography variant="subtitle1" sx={{ color: '#64748B', fontWeight: 600 }}>
                    Companies
                  </Typography>
                </Box>
                <Typography variant="h3" sx={{ mb: 1, color: '#0F172A', fontWeight: 700 }}>
                  {companyStats.total}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Tooltip title="Active Companies">
                    <Chip 
                      label={`${companyStats.active} active`}
                      size="small"
                      color="success"
                      sx={{ height: 20 }}
                    />
                  </Tooltip>
                  <Typography variant="body2" sx={{ color: '#64748B' }}>
                    {companyStats.avgMembers} avg. members
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 8px -1px rgba(0,0,0,0.1)',
              }
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <DocumentCheckIcon className="w-6 h-6 text-indigo-500 mr-2" />
                  <Typography variant="subtitle1" sx={{ color: '#64748B', fontWeight: 600 }}>
                    Security Tests
                  </Typography>
                </Box>
                <Typography variant="h3" sx={{ mb: 1, color: '#0F172A', fontWeight: 700 }}>
                  {recentReports.length}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {testData.findings.critical > 0 && (
                    <Tooltip title="Critical Findings">
                      <Chip 
                        label={`${testData.findings.critical} critical`}
                        size="small"
                        color="error"
                        sx={{ height: 20 }}
                      />
                    </Tooltip>
                  )}
                  {testData.findings.high > 0 && (
                    <Tooltip title="High Severity Findings">
                      <Chip 
                        label={`${testData.findings.high} high`}
                        size="small"
                        color="warning"
                        sx={{ height: 20 }}
                      />
                    </Tooltip>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 8px -1px rgba(0,0,0,0.1)',
              }
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <ClockIcon className="w-6 h-6 text-indigo-500 mr-2" />
                  <Typography variant="subtitle1" sx={{ color: '#64748B', fontWeight: 600 }}>
                    Last Activity
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 1, color: '#0F172A', fontWeight: 500 }}>
                  {users[0]?.email}
                </Typography>
                <Typography variant="body2" sx={{ color: '#64748B' }}>
                  {new Date(users[0]?.last_login).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Recent Activity Section */}
          <Grid item xs={12}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              overflow: 'hidden'
            }}>
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h6" sx={{ mb: 3, color: '#0F172A', fontWeight: 600 }}>
                  Recent Activity
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        width: 200,
                        height: 200,
                        borderRadius: '50%',
                        background: `conic-gradient(
                          #6366F1 0% ${testData.successful}%,
                          #C7D2FE ${testData.successful}% 100%
                        )`,
                        boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2
                    }}>
                      <Box sx={{ 
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        bgcolor: '#6366F1',
                        mr: 1
                      }} />
                      <Typography sx={{ color: '#0F172A', fontWeight: 500 }}>
                        Successful tests: {testData.successful}%
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <Box sx={{ 
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        bgcolor: '#C7D2FE',
                        mr: 1
                      }} />
                      <Typography sx={{ color: '#64748B' }}>
                        Failed tests: {testData.failed}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Companies Section */}
          <Grid item xs={12}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              overflow: 'hidden'
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h6" sx={{ color: '#0F172A', fontWeight: 600 }}>
                    Companies
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Total Members">
                      <Chip 
                        icon={<UsersIcon className="w-4 h-4" />}
                        label={`${companyStats.totalMembers} members`}
                        size="small"
                      />
                    </Tooltip>
                    <Tooltip title="Average Members per Company">
                      <Chip 
                        label={`${companyStats.avgMembers} avg`}
                        size="small"
                        variant="outlined"
                      />
                    </Tooltip>
                  </Box>
                </Box>
                <Grid container spacing={3}>
                  {companies.map((company) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                      <Card sx={{ 
                        height: '100%',
                        boxShadow: '0 2px 4px -1px rgba(0,0,0,0.1)',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
                        }
                      }}>
                        <CardContent sx={{ p: 3 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            {company.avatar ? (
                              <img src={company.avatar} alt={company.name} style={{ width: 64, height: 64, marginBottom: 16, borderRadius: '50%' }} />
                            ) : (
                              <Box 
                                sx={{ 
                                  width: 64, 
                                  height: 64, 
                                  bgcolor: '#818CF8',
                                  borderRadius: '50%', 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'center',
                                  marginBottom: 2,
                                  color: 'white',
                                  fontSize: '1.5rem',
                                  fontWeight: 600,
                                  boxShadow: '0 4px 6px -1px rgba(99,102,241,0.4)',
                                }}
                              >
                                {company.name[0].toUpperCase()}
                              </Box>
                            )}
                            <Typography variant="h6" sx={{ mb: 1, color: '#0F172A', fontWeight: 600 }}>
                              {company.name}
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1, color: '#64748B' }}>
                              {company.email}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                              <Tooltip title="Website">
                                <Chip 
                                  label={company.website.replace(/^https?:\/\//, '')}
                                  size="small"
                                  sx={{ height: 24 }}
                                />
                              </Tooltip>
                            </Box>
                            <Box sx={{ 
                              display: 'flex', 
                              justifyContent: 'space-between', 
                              width: '100%',
                              p: 2,
                              bgcolor: '#F8FAFC',
                              borderRadius: 2
                            }}>
                              <Box>
                                <Typography variant="body2" sx={{ color: '#64748B', mb: 0.5 }}>
                                  Members
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Typography variant="h6" sx={{ color: '#0F172A', fontWeight: 600 }}>
                                    {company.member_count}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: '#64748B' }}>
                                    / {company.user_limit}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography variant="body2" sx={{ color: '#64748B', mb: 0.5 }}>
                                  Created
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#0F172A' }}>
                                  {new Date(company.created_at).toLocaleDateString()}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Recent Reports Section */}
          <Grid item xs={12}>
            <Card sx={{ 
              boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
              bgcolor: 'white',
              borderRadius: 3,
              overflow: 'hidden'
            }}>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h6" sx={{ color: '#0F172A', fontWeight: 600 }}>
                    Recent Reports
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {testData.findings.critical > 0 && (
                      <Chip 
                        label={`${testData.findings.critical} Critical`}
                        size="small"
                        color="error"
                      />
                    )}
                    {testData.findings.high > 0 && (
                      <Chip 
                        label={`${testData.findings.high} High`}
                        size="small"
                        color="warning"
                      />
                    )}
                    {testData.findings.medium > 0 && (
                      <Chip 
                        label={`${testData.findings.medium} Medium`}
                        size="small"
                        color="info"
                      />
                    )}
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  {recentReports.map((report) => {
                    const reportData = JSON.parse(report.report) as ReportData;
                    const criticalCount = reportData.findings?.filter(f => f.severity === 'Critical').length || 0;
                    const highCount = reportData.findings?.filter(f => f.severity === 'High').length || 0;

                    return (
                      <Grid item xs={12} key={report.id}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'space-between', 
                          p: 3,
                          bgcolor: '#F8FAFC',
                          borderRadius: 2,
                          transition: 'transform 0.2s',
                          '&:hover': {
                            transform: 'translateX(4px)',
                            bgcolor: '#F1F5F9'
                          }
                        }}>
                          <Box>
                            <Typography variant="subtitle2" sx={{ color: '#0F172A', fontWeight: 600, mb: 0.5 }}>
                              {report.project_name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <Typography variant="body2" sx={{ color: '#64748B' }}>
                                {report.product}
                              </Typography>
                              {criticalCount > 0 && (
                                <Chip 
                                  label={`${criticalCount} Critical`}
                                  size="small"
                                  color="error"
                                  sx={{ height: 20 }}
                                />
                              )}
                              {highCount > 0 && (
                                <Chip 
                                  label={`${highCount} High`}
                                  size="small"
                                  color="warning"
                                  sx={{ height: 20 }}
                                />
                              )}
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Typography variant="caption" sx={{ color: '#64748B' }}>
                              {new Date(report.created_at).toLocaleString()}
                            </Typography>
                            <Typography variant="caption" sx={{ 
                              color: reportData.status === 'completed' ? 'success.main' : 'warning.main',
                              fontWeight: 500
                            }}>
                              {reportData.status}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* User Activity Chart */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ height: 400 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2 }}>User Activity Trend</Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveLine
                      data={userActivityData}
                      margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
                      xScale={{ type: 'point' }}
                      yScale={{ type: 'linear', min: 0, max: 'auto' }}
                      curve="monotoneX"
                      axisBottom={{
                        tickRotation: -45,
                        format: (value) => format(parseISO(value), 'MMM d'),
                      }}
                      pointSize={8}
                      pointColor="#fff"
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      enableArea={true}
                      areaOpacity={0.1}
                      enableGridX={false}
                      theme={{
                        axis: { domain: { line: { stroke: '#94a3b8' } } },
                        grid: { line: { stroke: '#e2e8f0' } },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: 400 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2 }}>Finding Severity Distribution</Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsivePie
                      data={securityMetrics.severityDistribution || []}
                      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      colors={{ datum: 'data.color' }}
                      arcLinkLabelsSkipAngle={10}
                      arcLinkLabelsTextColor="#64748B"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2 }}>Security Test Results Trend</Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveBar
                      data={securityMetrics.trendData || []}
                      keys={['Passed', 'Failed']}
                      indexBy="date"
                      margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
                      padding={0.3}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      colors={['#22c55e', '#ef4444']}
                      axisBottom={{
                        tickRotation: -45,
                        format: (value) => format(parseISO(value), 'MMM d'),
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      groupMode="stacked"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
