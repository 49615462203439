import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  CircularProgress,
  Chip,
  FormControl,
  InputLabel,
  Alert,
  Avatar,
  InputAdornment,
  Grid,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import api from '../../api/axios';

interface Company {
  id: number;
  name: string;
  email: string;
  website: string | null;
  address: string | null;
  phone: string | null;
  avatar: string | null;
  description: string | null;
  user_limit: number;
  member_count: number;
  created_at: string;
}

interface CompanyMember {
  id: number;
  username: string
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  status: string;
  joined_at: string;
  invited_by: number;
  user_email: string;
  company_name: string;
}

interface CompaniesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Company[];
}

interface RowProps {
  company: Company;
  onMemberUpdate: () => void;
}

interface FormData {
  name: string;
  email: string;
  website: string;
  address: string;
  phone: string;
  description: string;
  user_limit: number;
}

const Row: React.FC<RowProps> = ({ company, onMemberUpdate }) => {
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState<CompanyMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('member');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [pendingRoleChanges, setPendingRoleChanges] = useState<{ [key: number]: string }>({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: company.name,
    email: company.email,
    website: company.website || '',
    address: company.address || '',
    phone: company.phone || '',
    description: company.description || '',
    user_limit: company.user_limit,
  });

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const response = await api.get<{results: CompanyMember[]}>(`/api/companies/${company.id}/members/`);
      setMembers(response.data.results || []);
    } catch (err) {
      console.error('Error fetching members:', err);
      setError('Failed to fetch members');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchMembers();
    }
  }, [open, company.id]);

  const handleClick = async () => {
    if (!open) {
      await fetchMembers();
    }
    setOpen(!open);
  };

  const handleInvite = async () => {
    try {
      await api.post(`/api/companies/${company.id}/members/invite/`, {
        email: inviteEmail,
        role: inviteRole,
      });
      setSuccess('Invitation sent successfully');
      setShowInviteDialog(false);
      setInviteEmail('');
      setInviteRole('member');
      await fetchMembers();
      onMemberUpdate();
    } catch (error: any) {
      setError(error.response?.data?.error || 'Error sending invitation');
    }
  };

  const handleRoleChange = (memberId: number, newRole: string) => {
    setPendingRoleChanges(prev => ({
      ...prev,
      [memberId]: newRole
    }));
  };

  const applyRoleChange = async (memberId: number) => {
    const newRole = pendingRoleChanges[memberId];
    if (!newRole) return;

    try {
      await api.post(`/api/companies/${company.id}/members/${memberId}/role/`, {
        role: newRole,
      });
      setSuccess('Role updated successfully');
      await fetchMembers();
      onMemberUpdate();
      // Clear the pending change after successful update
      setPendingRoleChanges(prev => {
        const { [memberId]: _, ...rest } = prev;
        return rest;
      });
    } catch (error: any) {
      setError(error.response?.data?.error || 'Error updating role');
    }
  };

  const handleRemoveMember = async (memberId: number) => {
    if (!window.confirm('Are you sure you want to remove this member?')) return;
    
    try {
      await api.delete(`/api/companies/${company.id}/members/${memberId}/`);
      setSuccess('Member removed successfully');
      await fetchMembers();
      onMemberUpdate();
    } catch (error: any) {
      setError(error.response?.data?.error || 'Error removing member');
    }
  };

  const handleEditClick = () => {
    setFormData({
      name: company.name,
      email: company.email,
      website: company.website || '',
      address: company.address || '',
      phone: company.phone || '',
      description: company.description || '',
      user_limit: company.user_limit,
    });
    setShowEditForm(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleUpdateSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      setError(null);
      
      await api.put(`/api/companies/${company.id}/`, formData);
      setSuccess('Company updated successfully!');
      
      // Refresh company details
      await fetchMembers();
      setShowEditForm(false);
      
      // Clear success message after delay
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to update company');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow 
        sx={{
          '& > *': { borderBottom: 'unset' },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <TableCell sx={{ width: '40px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleClick();
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: '50px' }}>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            {company.name.charAt(0).toUpperCase()}
          </Avatar>
        </TableCell>
        <TableCell>{company.name}</TableCell>
        <TableCell>{company.email}</TableCell>
        <TableCell>{company.website || 'N/A'}</TableCell>
        <TableCell>{company.phone || 'N/A'}</TableCell>
        <TableCell>
          <Chip
            label={`${company.member_count} / ${company.user_limit}`}
            color={company.member_count >= company.user_limit ? 'error' : 'success'}
            size="small"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '20px 0' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, px: 2 }}>
                <Typography variant="h6" component="div">
                  Company Details
                </Typography>
                <Box>
                  <Button
                    startIcon={<EditIcon />}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEditClick()}
                    sx={{ mr: 1 }}
                  >
                    EDIT DETAILS
                  </Button>
                  <Button
                    startIcon={<PersonAddIcon />}
                    variant="contained"
                    color="primary"
                    onClick={() => setShowInviteDialog(true)}
                  >
                    INVITE MEMBER
                  </Button>
                </Box>
              </Box>

              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Basic Information
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <EmailIcon color="action" />
                        <Typography><strong>Email:</strong> {company.email}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LanguageIcon color="action" />
                        <Typography><strong>Website:</strong> {company.website || 'N/A'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <PhoneIcon color="action" />
                        <Typography><strong>Phone:</strong> {company.phone || 'N/A'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocationOnIcon color="action" />
                        <Typography><strong>Address:</strong> {company.address || 'N/A'}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DescriptionIcon color="action" />
                        <Typography><strong>Description:</strong> {company.description || 'N/A'}</Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Subscription Details
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <GroupIcon color="action" />
                        <Typography>
                          <strong>Members:</strong>{' '}
                          <Chip
                            label={`${company.member_count} / ${company.user_limit}`}
                            color={company.member_count >= company.user_limit ? 'error' : 'success'}
                            size="small"
                          />
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalendarTodayIcon color="action" />
                        <Typography>
                          <strong>Created:</strong> {new Date(company.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              {error && (
                <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
                  {error}
                </Alert>
              )}
              {success && (
                <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccess(null)}>
                  {success}
                </Alert>
              )}

              {!showEditForm ? (
                <></>
              ) : (
                <Paper sx={{ p: 3, mb: 3, backgroundColor: 'background.paper' }}>
                  <Typography variant="h6" sx={{ mb: 3, color: 'primary.main' }}>
                    Edit Company Details
                  </Typography>
                  <form onSubmit={handleUpdateSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                          disabled={loading}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                          disabled={loading}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Website"
                          name="website"
                          value={formData.website}
                          onChange={handleInputChange}
                          disabled={loading}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          disabled={loading}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                          disabled={loading}
                          multiline
                          rows={2}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Description"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          disabled={loading}
                          multiline
                          rows={3}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="User Limit"
                          name="user_limit"
                          type="number"
                          value={formData.user_limit}
                          onChange={handleInputChange}
                          required
                          disabled={loading}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <GroupIcon color="action" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => setShowEditForm(false)}
                        disabled={loading}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                      >
                        {loading ? 'Updating...' : 'Save Changes'}
                      </Button>
                    </Box>
                  </form>
                </Paper>
              )}

              <Box sx={{ mt: 3, px: 2 }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Company Members
                </Typography>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Username</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {members.map((member) => (
                        <TableRow key={member.id}>
                          <TableCell>{member.username}</TableCell>
                          <TableCell>{`${member.first_name} ${member.last_name}`}</TableCell>
                          <TableCell>{member.email}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Select
                                value={pendingRoleChanges[member.id] || member.role}
                                size="small"
                                onChange={(e) => handleRoleChange(member.id, e.target.value)}
                                sx={{ minWidth: 120 }}
                              >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="member">Member</MenuItem>
                              </Select>
                              {pendingRoleChanges[member.id] && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  onClick={() => applyRoleChange(member.id)}
                                  startIcon={<CheckIcon />}
                                >
                                  Apply
                                </Button>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={member.status}
                              color={member.status === 'active' ? 'success' : 'warning'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveMember(member.id);
                              }}
                              startIcon={<DeleteIcon />}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" component="div">
            Invite New Member
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel>Role</InputLabel>
            <Select
              value={inviteRole}
              onChange={(e) => setInviteRole(e.target.value)}
              label="Role"
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={() => setShowInviteDialog(false)}
            color="inherit"
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            onClick={handleInvite}
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
          >
            Send Invitation
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AdminCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get<CompaniesResponse>('/api/companies/');
      setCompanies(response.data.results);
    } catch (err) {
      setError('Failed to fetch companies');
      console.error('Error fetching companies:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Website</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Members</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((company) => (
            <Row key={company.id} company={company} onMemberUpdate={fetchCompanies} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminCompanies;
